import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkStack, SprkStackItem, SprkFlag, SprkHeading, SprkIcon, SprkDivider, SprkText } from '@sparkdesignsystem/spark-react';
import pageTitleDo from '../../../images/typography/pagetitles_do.png';
import pageTitleDo2x from '../../../images/typography/pagetitles_do@2x.png';
import pageTitleDont from '../../../images/typography/pagetitle_dont1.png';
import pageTitleDont2x from '../../../images/typography/pagetitle_dont1@2x.png';
import pageTitleDont2 from '../../../images/typography/pagetitle_dont2.png';
import pageTitleDont2_2x from '../../../images/typography/pagetitle_dont2@2x.png';
import specimen from '../../../images/typography/RocketTypeSpecimenGraphic-01.png';
import specimen2x from '../../../images/typography/RocketTypeSpecimenGraphic-01@2x.png';
import TypographyTable from '../../../components/TypographyTable';
export const pageTitleDoSet = `${pageTitleDo} 560w, ${pageTitleDo2x} 1120w`;
export const pageTitleDontSet = `${pageTitleDont} 560w, ${pageTitleDont2x} 1120w`;
export const pageTitleDont2Set = `${pageTitleDont2} 560w, ${pageTitleDont2_2x} 1120w`;
export const specimenSet = `${specimen} 560w, ${specimen2x} 1120w`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageTitleDoSet,
  pageTitleDontSet,
  pageTitleDont2Set,
  specimenSet,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">












    <h1>{`Typography`}</h1>
    <p>{`Typography is fundamental to our Rocket experiences. We use a custom developed typeface called Rocket Sans.`}</p>
    <p>{`Our guidelines optimize content hierarchy by using text sizes, line heights, colors, and font. These factors contribute to what makes content clear and appealing.`}</p>
    <p>{`Combine these header and body styles to establish content hierarchy within your project.`}</p>
    <div className="sprk-u-Measure">
  <img srcSet={specimenSet} src={specimen} sizes="(min-width: 1120px) 1120px,
              (min-width: 1680px) 1680px,
              560px" alt="Rocket Sans, created by Monotype in 2018. Our proprietary typeface,
      Rocket Sans, is crisp, expressive, bold and confident. It's modern,
      aligned to our brand ethos and approachable, without sacrificing
      legibility and scalability. We use this custom built typeface for
      all of our brand expressions." />
    </div>
    <SprkStack itemSpacing="medium" mdxType="SprkStack">
  <SprkStackItem mdxType="SprkStackItem">
    <h2 className="docs-b-h2 sprk-b-TypeDisplayTwo sprk-u-Measure">Table of Contents</h2>
  </SprkStackItem>
  <SprkStackItem mdxType="SprkStackItem">
        <ul className="sprk-b-List sprk-b-List--bare">
  <li>
    <a href="#size-reference-charts">
      Size Reference Charts
    </a>
  </li>
  <li>
    <a href="#headers">
      Headers
    </a>
    <ul className="sprk-b-List sprk-b-List--indented sprk-u-Measure">
      <li>
        <a href="#page-title">
          Page Title
        </a>
      </li>
      <li>
        <a href="#display-one">
          Display One
        </a>
      </li>
    <li>
        <a href="#display-two">
          Display Two
        </a>
      </li>
      <li>
        <a href="#display-three">
          Display Three
        </a>
      </li>
      <li>
        <a href="#display-four">
          Display Four
        </a>
      </li>
      <li>
        <a href="#display-five">
          Display Five
        </a>
      </li>
      <li>
        <a href="#display-six">
          Display Six
        </a>
      </li>
      <li>
        <a href="#display-seven">
          Display Seven
        </a>
      </li>
    </ul>
  </li>
  <li>
    <a href="#body-text">
      Body Text
    </a>
    <ul className="sprk-b-List sprk-b-List--indented sprk-u-Measure">
      <li>
        <a href="#body-one">
          Body One
        </a>
      </li>
      <li>
        <a href="#body-two">
          Body Two
        </a>
      </li>
      <li>
        <a href="#body-three">
          Body Three
        </a>
      </li>
      <li>
        <a href="#body-four">
          Body Four
        </a>
      </li>
    </ul>
  </li>
        </ul>
  </SprkStackItem>
    </SprkStack>
    <SprkDivider element="span" mdxType="SprkDivider" />
    <h2>{`Size Reference Charts`}</h2>
    <p>{`Our typography has 7 display styles, 3 body copy styles, and 1 legal type size.
The size reference chart is an example of typography when designing.
However, when developing a page, reference the Storybook components below.`}</p>
    <div className="sprk-o-Stack sprk-o-Stack--medium sprk-o-Stack--split@xl">
  <div className="sprk-b-TableContainer sprk-o-Stack__item">
    <TypographyTable category="desktop" mdxType="TypographyTable" />
  </div>
  <div className="sprk-b-TableContainer sprk-o-Stack__item">
    <TypographyTable category="mobile" mdxType="TypographyTable" />
  </div>
    </div>
    <SprkDivider element="span" mdxType="SprkDivider" />
    <h2>{`Headers`}</h2>
    <h3>{`Page Title`}</h3>
    <p>{`Use one Page Title per page to serve as the main heading. They differentiate themselves from other headings with their red accent line.`}</p>
    <p>{`Use Page Title with `}<a parentName="p" {...{
        "href": "#display-one"
      }}>{`Display One`}</a>{` to style primary headings on top-level pages in your experience.`}</p>
    <p>{`Use Page Title with `}<a parentName="p" {...{
        "href": "#display-two"
      }}>{`Display Two`}</a>{` to style primary headings on nested pages in your experience.`}</p>
    <ComponentPreview componentName="typography--page-title" componentType="foundations" titleAttr="Page Title Example" hasHTML hasReact hasAngular mdxType="ComponentPreview" />
    <div className="sprk-u-Measure">
  <a className="sprk-b-Link sprk-u-Float--right" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <SprkStack itemSpacing="medium" mdxType="SprkStack">
  <SprkStackItem mdxType="SprkStackItem">
    <SprkFlag additionalClasses="sprk-o-Flag--middle" media={<SprkIcon iconName="check-mark" additionalClasses="sprk-c-Icon--l sprk-c-Icon--stroke-current-color sprk-c-Icon--filled-current-color sprk-u-Color--green" aria-hidden="true" mdxType="SprkIcon" />} mdxType="SprkFlag">
      <SprkHeading element="span" variant="displayFour" mdxType="SprkHeading">
        Do
      </SprkHeading>
    </SprkFlag>
  </SprkStackItem>
  <SprkStackItem mdxType="SprkStackItem">
    <SprkStack itemSpacing="small" mdxType="SprkStack">
      <SprkStackItem mdxType="SprkStackItem">
        <div className="sprk-u-Measure">
          <img srcSet={pageTitleDoSet} src={pageTitleDo} sizes="(min-width: 1120px) 1120px,
                          (min-width: 1680px) 1680px,
                          560px" alt="A page layout featuring a primary and secondary heading. Each headline is left-aligned." />
        </div>
      </SprkStackItem>
      <SprkStackItem mdxType="SprkStackItem">
        <SprkText variant="bodyTwo" additionalClasses="sprk-u-Measure" mdxType="SprkText">
          Use page titles at the top left of the page, and left-align the headline with
          the red line.
        </SprkText>
      </SprkStackItem>
    </SprkStack>
  </SprkStackItem>
  <SprkStackItem mdxType="SprkStackItem">
    <SprkFlag additionalClasses="sprk-o-Flag--middle" media={<SprkIcon iconName="close-circle" additionalClasses="sprk-c-Icon--l sprk-c-Icon--stroke-current-color sprk-c-Icon--filled-current-color sprk-u-Color--orange" aria-hidden="true" mdxType="SprkIcon" />} mdxType="SprkFlag">
      <SprkHeading element="span" variant="displayFour" mdxType="SprkHeading">
        Don't
      </SprkHeading>
    </SprkFlag>
  </SprkStackItem>
  <SprkStackItem mdxType="SprkStackItem">
    <SprkStack itemSpacing="medium" splitAt="small" mdxType="SprkStack">
      <SprkStackItem additionalClasses="sprk-o-Stack__item--half@s" mdxType="SprkStackItem">
        <SprkStack itemSpacing="small" mdxType="SprkStack">
          <SprkStackItem mdxType="SprkStackItem">
            <img srcSet={pageTitleDontSet} src={pageTitleDont} sizes="(min-width: 1120px) 1120px,
                    (min-width: 1680px) 1680px,
                    560px" alt="A page layout featuring a primary and secondary heading. The primary heading is center-aligned." />
          </SprkStackItem>
          <SprkStackItem mdxType="SprkStackItem">
            <SprkText variant="bodyTwo" additionalClasses="sprk-u-Measure" mdxType="SprkText">
              Do not center-align page titles.
            </SprkText>
          </SprkStackItem>
        </SprkStack>
      </SprkStackItem>
      <SprkStackItem additionalClasses="sprk-o-Stack__item--half@s" mdxType="SprkStackItem">
        <SprkStack itemSpacing="small" mdxType="SprkStack">
          <SprkStackItem mdxType="SprkStackItem">
            <img srcSet={pageTitleDont2Set} src={pageTitleDont2} sizes="(min-width: 1120px) 1120px,
                        (min-width: 1680px) 1680px,
                        560px" alt="A page layout featuring two primary headings." />
          </SprkStackItem>
          <SprkStackItem mdxType="SprkStackItem">
            <SprkText variant="bodyTwo" additionalClasses="sprk-u-Measure" mdxType="SprkText">
              To maintain content hierarchy, do not use multiple page titles on one page.
            </SprkText>
          </SprkStackItem>
        </SprkStack>
      </SprkStackItem>
    </SprkStack>
  </SprkStackItem>
    </SprkStack>
    <h3>{`Display One`}</h3>
    <p>{`Use for primary headings and `}<a parentName="p" {...{
        "href": "#page-title"
      }}>{`Page Titles`}</a>{`.`}</p>
    <ComponentPreview componentName="typography--display-one" componentType="foundations" titleAttr="Display One Example" hasHTML hasReact hasAngular mdxType="ComponentPreview" />
    <div className="sprk-u-Measure">
  <a className="sprk-b-Link sprk-u-Float--right" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Display Two`}</h3>
    <p>{`Use for secondary headings and `}<a parentName="p" {...{
        "href": "#page-title"
      }}>{`Page Titles`}</a>{`.`}</p>
    <ComponentPreview componentName="typography--display-two" componentType="foundations" titleAttr="Display Two Example" hasHTML hasReact hasAngular mdxType="ComponentPreview" />
    <div className="sprk-u-Measure">
  <a className="sprk-b-Link sprk-u-Float--right" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Display Three`}</h3>
    <p>{`Use for third-level headings.`}</p>
    <ComponentPreview componentName="typography--display-three" componentType="foundations" titleAttr="Display Three Example" hasHTML hasReact hasAngular mdxType="ComponentPreview" />
    <div className="sprk-u-Measure">
  <a className="sprk-b-Link sprk-u-Float--right" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Display Four`}</h3>
    <p>{`Use for fourth-level headings.`}</p>
    <ComponentPreview componentName="typography--display-four" componentType="foundations" titleAttr="Display Four Example" hasHTML hasReact hasAngular mdxType="ComponentPreview" />
    <div className="sprk-u-Measure">
  <a className="sprk-b-Link sprk-u-Float--right" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Display Five`}</h3>
    <p>{`Use for fifth-level headings.`}</p>
    <ComponentPreview componentName="typography--display-five" componentType="foundations" titleAttr="Display Five Example" hasHTML hasReact hasAngular mdxType="ComponentPreview" />
    <div className="sprk-u-Measure">
  <a className="sprk-b-Link sprk-u-Float--right" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Display Six`}</h3>
    <p>{`Use for sixth-level headings.`}</p>
    <ComponentPreview componentName="typography--display-six" componentType="foundations" titleAttr="Display Six Example" hasHTML hasReact hasAngular mdxType="ComponentPreview" />
    <div className="sprk-u-Measure">
  <a className="sprk-b-Link sprk-u-Float--right" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Display Seven`}</h3>
    <p>{`Use for seventh-level headings.`}</p>
    <ComponentPreview componentName="typography--display-seven" componentType="foundations" titleAttr="Display Seven Example" hasHTML hasReact hasAngular mdxType="ComponentPreview" />
    <div className="sprk-u-Measure">
  <a className="sprk-b-Link sprk-u-Float--right" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h2>{`Body Text`}</h2>
    <h3>{`Body One`}</h3>
    <p>{`Use to emphasize text within paragraphs and for sub-labels.`}</p>
    <ComponentPreview componentName="typography--body-one" componentType="foundations" titleAttr="Body One Example" hasHTML hasReact hasAngular mdxType="ComponentPreview" />
    <div className="sprk-u-Measure">
  <a className="sprk-b-Link sprk-u-Float--right" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Body Two`}</h3>
    <p>{`Use for all paragraph and non-heading text.`}</p>
    <ComponentPreview componentName="typography--body-two" componentType="foundations" titleAttr="Body Two Example" hasHTML hasReact hasAngular mdxType="ComponentPreview" />
    <div className="sprk-u-Measure">
  <a className="sprk-b-Link sprk-u-Float--right" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Body Three`}</h3>
    <p>{`Use for very long sections of text. It has a larger line height which assists with readability.`}</p>
    <ComponentPreview componentName="typography--body-three" componentType="foundations" titleAttr="Body Three Example" hasHTML hasReact hasAngular mdxType="ComponentPreview" />
    <div className="sprk-u-Measure">
  <a className="sprk-b-Link sprk-u-Float--right" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Body Four`}</h3>
    <p>{`Use for legal or de-emphasized text.`}</p>
    <ComponentPreview componentName="typography--body-four" componentType="foundations" titleAttr="Body Four Example" hasHTML hasReact hasAngular mdxType="ComponentPreview" />
    <div className="sprk-u-Measure">
  <a className="sprk-b-Link sprk-u-Float--right" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      